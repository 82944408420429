import copyToClipboard from 'copy-to-clipboard';

const handleCopyLink = copyLink => {
  let linkDisabled;
  if (linkDisabled) {
    return false;
  }

  $(copyLink).popup({
    on: 'manual',
    position: 'right center',
    lastResort: 'right center',
    variation: 'mini',
    content: 'Copied to clipboard',
  }).popup('show');
  linkDisabled = true;
  setTimeout(() => {
    $(copyLink).popup('destroy');
    linkDisabled = false;
  }, 1250);

  return true;
};

export const dataCopyValue = (doc = document) =>
    doc.querySelectorAll('a.anchor, [data-copy-value]').forEach(copyEl => {
      copyEl.addEventListener('click', event => {
        event.stopPropagation();
        event.preventDefault();

        if (!handleCopyLink(copyEl)) {
          return;
        }

        copyToClipboard(
            copyEl.getAttribute('data-copy-value') || copyEl.href.trim(), {
              format: 'text/plain',
            });

        copyEl.focus();
      });
    });