import 'fomantic-ui-less/definitions/globals/reset.less';
import 'fomantic-ui-less/definitions/globals/site.less';

import 'fomantic-ui-less/definitions/elements/button.less';
import 'fomantic-ui-less/definitions/elements/container.less';
import 'fomantic-ui-less/definitions/elements/divider.less';
import 'fomantic-ui-less/definitions/elements/header.less';
import 'fomantic-ui-less/definitions/elements/icon.less';
import 'fomantic-ui-less/definitions/elements/image.less';
import 'fomantic-ui-less/definitions/elements/input.less';
import 'fomantic-ui-less/definitions/elements/label.less';
import 'fomantic-ui-less/definitions/elements/list.less';
import 'fomantic-ui-less/definitions/elements/segment.less';
import 'fomantic-ui-less/definitions/elements/loader.less';
import 'fomantic-ui-less/definitions/elements/text.less';
import 'fomantic-ui-less/definitions/elements/reveal.less';

import 'fomantic-ui-less/definitions/collections/breadcrumb.less';
import 'fomantic-ui-less/definitions/collections/form.less';
import 'fomantic-ui-less/definitions/collections/grid.less';
import 'fomantic-ui-less/definitions/collections/menu.less';
import 'fomantic-ui-less/definitions/collections/message.less';
import 'fomantic-ui-less/definitions/collections/table.less';

import 'fomantic-ui-less/definitions/views/card.less';
import 'fomantic-ui-less/definitions/views/item.less';
import 'fomantic-ui-less/definitions/views/statistic.less';

import 'fomantic-ui-less/definitions/modules/transition.less';
import 'fomantic-ui-less/definitions/modules/accordion.less';
import 'fomantic-ui-less/definitions/modules/search.less';
import 'fomantic-ui-less/definitions/modules/dropdown.less';
import 'fomantic-ui-less/definitions/modules/checkbox.less';
import 'fomantic-ui-less/definitions/modules/popup.less';
import 'fomantic-ui-less/definitions/modules/sticky.less';
import 'fomantic-ui-less/definitions/modules/progress.less';
import 'fomantic-ui-less/definitions/modules/modal.less';
import 'fomantic-ui-less/definitions/modules/dimmer.less';

import 'fomantic-ui-less/definitions/behaviors/api';

import 'fomantic-ui-less/definitions/modules/transition';
import 'fomantic-ui-less/definitions/modules/accordion';
import 'fomantic-ui-less/definitions/modules/search';
import 'fomantic-ui-less/definitions/modules/dropdown';
import 'fomantic-ui-less/definitions/modules/popup';
import 'fomantic-ui-less/definitions/modules/sticky';

import '@github/relative-time-element';

import '@mdi/svg/svg/chevron-down.svg';
import '@mdi/svg/svg/chevron-left.svg';
import '@mdi/svg/svg/chevron-double-left.svg';
import '@mdi/svg/svg/chevron-right.svg';
import '@mdi/svg/svg/dots-horizontal.svg';
import '@mdi/svg/svg/magnify.svg';
import '@mdi/svg/svg/web.svg';

import {dataCopyValue} from './Common/copyToClipboard';

window.enableSimWorkflowLog = () => localStorage.setItem("simworkflow.log", "all");
window.disableSimWorkflowLog = () => localStorage.removeItem("simworkflow.log");

$(document).ready(() => {
    $(document.querySelectorAll('.ui.dropdown:not(.page-footer .ui.dropdown, .search, .markdown-body.task *, table[data-id][data-tasks] *)')).dropdown({
        duration: 0
    });
    $(document.querySelectorAll('.ui.dropdown.search:not(.markdown-body.task *, table[data-id][data-tasks] *)')).dropdown({
        fullTextSearch: true,
        duration: 0
    });
    $(document.getElementById('page-header-dropdown')).dropdown({
        action: 'nothing',
        selectOnKeydown: false,
        duration: 0
    });
    $(document.querySelectorAll('div.message .close')).on('click', function () {
        $(this).closest('.ui.message').transition('fade');
    });
    $(document.querySelectorAll('.form .ui.multiple.dropdown.addition')).dropdown({
        duration: 0,
        showNoResults: false,
        allowAdditions: true
    });
    dataCopyValue();
});